import React from 'react';
import Icon1 from '../../images/box-bw.jpg';
import Icon2 from '../../images/tolva_dibujo.jpg';
import Icon3 from '../../images/monitoreo.jpg';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Card from '../../components/Card/Cards';

const Services = () => {
  return (
    <ServicesContainer id='services'>
       <Card />
    </ServicesContainer>
  );
};

export default Services;
