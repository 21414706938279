import React from "react";
import './Card.css';


const Card = props =>{
    return(
        <div className="card text-center shadow">
            <div className="overflow">
                <img src={props.imgsrc} alt={props.name} className="card-img-top" width="20" height="300" />
            </div>
            <div className="card-body text-dark">
                <h4 className="card-title">{props.title}</h4>
                <p className="card-text-secondary" >{props.descripcion}</p>
                <a href={props.link} className="btn btn-outline-success">Más información</a>
            </div>
        </div>
        
    );
}

export default Card;