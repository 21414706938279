export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: false,
  lightTextDesc: true,
  topLine: 'NOSOTROS',
  headline: '¿Quienes Somos?',
  description:
    'Somos un grupo de profesionistas comprometidos con generar valor a la sociedad, '+
    'accionistas y colaboradores satisfaciendo las necesidades de nuestros clientes '+
    'gracias a nuestra experiencia, calidad e innovación implementadas en cada uno '+ 
    'de los trabajos en los que participamos, con honradez y siempre respetando el '+
    'medio ambiente, crear soluciones sostenibles para las operaciones actuales y futuras',
  imgStart: false,
  img: require('../../images/hand.jpg'),
  alt: 'Equipo',
  dark: true,
  primary: true,
  darkText: true
};

export const homeObjTwo2 = {
  id: 'unidades',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Unlimited Access',
  headline: 'Login to your account at any time',
  description:
    'We have you covered no matter where you are located. All you need is an internet connection and a phone or computer.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/svg-2.svg'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};


export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Unlimited Access',
  headline: 'Login to your account at any time',
  description:
    'We have you covered no matter where you are located. All you need is an internet connection and a phone or computer.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/svg-2.svg'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Join our Team',
  headline: 'Creating an account is extremely easy',
  description:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: 'Start Now',
  imgStart: false,
  img: require('../../images/svg-3.svg'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true
};

