import React, {Component} from "react";
import Card from "./Card";
import {
  CardNiument, CardViumark, CardIntelinmuebles
} from './data';
import img1 from '../../images/codigo.jpg';
import img2 from '../../images/mercado.jpg';
import img3 from '../../images/inmueble.jpg'; 

import imgL1 from '../../images/logoniument.png'; 

class Cards extends Component{
    render(){
        return(
            <div className="container-fluid d-flex justify-content-center">
                <div className="row">
                    <div className="col-md-4">
                        <Card imgsrc={img1} imgname={"Niument"} title ={CardNiument.title} descripcion={CardNiument.description} link={CardNiument.links} />
                    </div>
                    <div className="col-md-4">
                        <Card imgsrc={img2} imgname={"Viumark"} title ={CardViumark.title} descripcion={CardViumark.description} link={CardViumark.links}/>   
                    </div>
                    <div className="col-md-4">
                        <Card imgsrc={img3} imgname={"Intelinmuebles"} title ={CardIntelinmuebles.title} descripcion={CardIntelinmuebles.description} link={CardIntelinmuebles.links}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Cards;