export const CardNiument = {
    title: 'Niument',
    description:
    'Profesionales con amplia experiencia en diferentes áreas estratégicas empresariales y que, '+
    'bajo el esquema de trabajo de cada cliente, le otorgamos las soluciones más completas e integrales,'+
    'creando ventajas competitivas que le permitan desenvolverse en su ramo de la mejor forma.',
    links: 'http://niument.com/'
  };

  export const CardViumark = {
    title: 'Viumark',
    description:
    'Desarrollo de proveedores del ramo alimenticio, '+
    'contamos con alianzas estratégicas a nivel nacional y en el extrangero '+
    'que nos permiten tanto ofrecer como disponer de productos en todo el mundo.',
    links: 'http://viumark.com/'
  };

  
  export const CardIntelinmuebles = {
    title: 'Intelinmuebles',
    description:
    'Equipo dedicado a brindar el mejor servicio de asesoría inmobiliaria, personalizada e integral, '+
    'orientada a cumplir con las necesidades de nuestros clientes. Todos nuestros trabajos se realizan '+
    'con la mayor ética, honestidad, profesionalismo y discreción en todas las etapas de la venta, compra o renta.',
    links: 'http://intelinmuebles.com/'
  };