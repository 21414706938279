import React, { useState } from 'react';
import { Button } from '../ButtonElements';

import Video from '../../videos/niubox.mp4';
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
} from './HeroElements';

function HeroSection() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id='home'>
      <HeroBg>
        <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <HeroH1>Soluciones Integrales Empresariales</HeroH1>
        <HeroP>
            Diseño, desarrollo e implementación de respuestas a tus necesidades
        </HeroP>
        <HeroBtnWrapper>
         
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
